import React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { PrensaP } from "../components/sections"

const PrensaPage = () => (
  <Layout>
    <SEO title="Page Prensa" />
    <PrensaP />
  </Layout>
)

export default PrensaPage
